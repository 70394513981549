<template>
  <article class="disclosure whistleblower">
    <h1>Our Whistleblower System</h1>
    <p>
      Complying with statutory regulations and internal rules, and the principles laid down in our Code of Conduct and
      the Code of Conduct for Business Partners, has top priority at Electrify Canada. The success of our company is
      based on Integrity and Compliance. To meet these standards, it is important to learn of potential employee or
      supplier misconduct and to put a stop to it. Therefore, we entrust the Volkswagen Group Central Investigation
      Office (“Investigation Office”) to operate an independent, impartial, and confidential Whistleblower System on our
      behalf.
    </p>
    <p>
      A key pillar of our Whistleblower System is the principle of procedural fairness. It also guarantees the greatest
      possible protection for whistleblowers, Persons Implicated, and employees contributing to the investigation of
      reported misconduct.
    </p>
    <p>
      This also includes offering opportunities for anonymous reporting and communication. We assure not to perform any
      steps in order to identify anonymous whistleblowers, who do not misuse our Whistleblower System. Retaliation of
      whistleblowers and all persons who contribute to investigations at Electrify Canada will not be tolerated. Persons
      Implicated are presumed innocent until the violation is proven. Investigations will be conducted with the utmost
      confidentiality. The information will be processed in a fair, fast, and protected process.
    </p>
    <h2 class="headline4">How do we process your report?</h2>
    <p>
      The qualified and experienced colleagues at the Investigation Office examine every report for potential misconduct
      by an Electrify America employee thoroughly and follow it up systematically. First, you will get a confirmation of
      receipt. The Investigation Office then assesses your report. This includes gathering facts particularly from the
      whistleblower. Only if this initial evaluation shows grounds for suspicion of a violation, an investigation by a
      dedicated Investigating Unit will be started. Afterwards, the results of the investigation will be assessed by the
      Investigation Office and appropriate measures will be recommended. Information about the status* and the outcome
      of the procedure will be given to you without undue delay.
    </p>
    <p>
      Potential violations of the Code of Conduct for Business Partners by suppliers, including serious risks and
      violations of human rights and environment by direct and indirect suppliers, can also be reported to the
      Investigation Office, as well as reports requiring otherwise immediate action. The Investigation Office will
      inform the responsible departments, who will process the issue accordingly. This particularly includes taking the
      necessary measures to minimize or end violations and/or risks.
    </p>
    <p>* The processing time varies depending on the subject of the procedure.</p>
    <p>
      Find more information on the procedural principles of our whistleblower system
      <a
        class="link"
        rel="noopener"
        target="_blank"
        href="https://www.volkswagenag.com/presence/konzern/compliance-und-risikomanagement/compliance/Volkswagen_Group_Complaints_Procedure.pdf"
        ><strong>here.</strong>
      </a>
    </p>
    <h2 class="headline4">Do you have any concern or feedback regarding an Electrify Canada product or service?</h2>
    <p>
      If you have any questions or feedback about services provided by Electrify Canada please contact
      <a href="mailto:support@electrify-canada.ca" class="link"><strong> support@electrify-canada.ca.</strong></a>
      We kindly ask for your understanding that the Whistleblower System unfortunately cannot process customer
      complaints.
    </p>
    <h2 class="headline4">Making a report to our Whistleblower System</h2>
    <h2 class="headline4 light">Volkswagen Group Central Investigation Office</h2>
    <p><strong>Whistleblower Hotline:</strong></p>
    <p>
      833-657-1574 (toll-free) <br />
      908-219-8092 (local)<br />
      Online:
      <a
        class="link"
        rel="noopener"
        target="_blank"
        href="https://www.bkms-system.com/bkwebanon/report/clientInfo?cin=22vwgroup16&language=eng"
        ><strong>Secure Reporting Channel</strong></a
      >
      <br />Email: <a href="mailto:io@Volkswagen.de" class="link"><strong>Investigation Office</strong></a>
    </p>
    <p>
      <strong>Postal address: </strong><br />Volkswagen AG, Central Investigation Office<br />Mailbox 1717<br />Berliner
      Ring 2<br />38436 Wolfsburg, Germany <br /><strong>In person: </strong><br />Please email
      <a href="mailto:io@Volkswagen.de" class="link"><strong>io@volkswagen.de</strong></a> to make an appointment.
    </p>
    <h2 class="headline4">Ombudspersons</h2>
    <p>
      Aside from the Central Investigation Office, there are two external lawyers (ombudspersons) who act as neutral
      mediators. The external ombudspersons appointed by the Volkswagen Group act as trusted lawyers who receive hints
      regarding possible regulatory violations and check whether they are plausible and substantive. Ultimately, they
      pass on all the information in the scope discussed with the whistleblower to the Central Investigation Office for
      further processing.
    </p>
    <p>
      If you want to get in contact with the Ombudsperson you can find their contact details here:
      <a class="link" rel="noopener" target="_blank" href="https://www.ombudsmen-of-volkswagen.com/"
        ><strong>https://www.ombudsmen-of-volkswagen.com/</strong></a
      >
    </p>
    <p><strong>Volkswagen Group of America</strong></p>
    <p>
      <strong>Ethics Hotline:</strong> 888-228-7317<br /><strong>Online: </strong>
      <a
        class="link"
        rel="noopener"
        target="_blank"
        href="https://secure.ethicspoint.com/domain/media/en/gui/66470/index.html"
        ><strong>EthicsPoint</strong></a
      >
    </p>
  </article>
</template>

<script>
export default {
  metaInfo: {
    title: `Whistleblower System | Electrify Canada`,
    meta: [
      {
        name: 'description',
        content: `Learn about our Whistleblower System and how to submit anonymous reports regarding a regulatory or code of conduct violation.`,
      },
    ],
  },
};
</script>
